html,
body {
  padding: 10px 0 0 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: lightgray;
}

* {
  box-sizing: border-box;
}

.story {
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}

.story .storyLinkWithText {
  font-size: 10px;
  color: gray;
}

.story .storyLower {
  font-size: 10px;
  padding-left:26px;
  color: gray;
}

.story .storyIndex {
  display: inline-block;
  width: 22px
}

.comment {
  margin-left: 20px;
}

.commentsContainer > .comment {
  margin-left: 5px;
  padding-bottom: 8px;
  border-top: 8px solid #eee;
}

.commentsContainer > .comment .comment {
  border-left: 1px solid #eee;
  padding-left: 5px;
}

.commentByLine {
  font-size: .8em;
  margin-top: 5px;
  cursor: pointer;
  color: #aaa;
}

.commentText {
  margin-top: 5px;
}
